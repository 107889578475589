import { orange, white } from 'src/styles/colors'
import styled from 'styled-components'

// Background
import BackgroundMobile from './assets/images/hero-background-branding-experience-mobile.png'
import BackgroundMD from './assets/images/hero-background-branding-experience-tablet.png'
import BackgroundLG from './assets/images/hero-background-branding-experience-lg.png'
import BackgroundXL from './assets/images/hero-background-branding-experience-xl.png'
import BackgroundXXXL from './assets/images/hero-background-branding-experience-xxl.png'

import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  background-color: ${white};
`

export const HeroBackgroundBanner = styled.section`
  background-image: url(${BackgroundMobile});
  width: 100%;
  height: 268px;
  background-size: cover;
  @media ${device.tablet} {
  background-image: url(${BackgroundMD});
  height: 391px;
  }

  @media ${device.desktopLG} {
  background-image: url(${BackgroundLG});
  height: 522px;
  }

  @media ${device.desktopXL} {
  background-image: url(${BackgroundXL});
  height: 733px;
  }

  @media ${device.desktopXXXL} {
  background-image: url(${BackgroundXXXL});
  width: 100%;
  height: 977px;
  }
`
