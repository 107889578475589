import React from 'react'
import * as S from './style'

import Layout from 'src/layouts/BaseLayout'
import pageContext from './pageContext.json'
import ExperienciaForm from './ExperienciaForm'

const ParceiroConsignado = () => {
  return (
    <Layout pageContext={pageContext}>
      <S.HeroBackgroundBanner />
      <S.Section className='py-5'>
        <div className='container'>
          <div className='row align-items-md-center'>
            <div className='col-12 col-md-6 col-lg-5'>
              <h1 className='fs-32 lh-40 fs-xl-40 lh-xl-50 fs-xxl-48 lh-xxl-60 fw-600 text-grayscale--500'>Curtir pode ser <span className='d-lg-block'>simples com o Inter</span></h1>
              <p className='fs-16 lh-20 fs-xl-18 lh-xl-22 text-grayscale--400 fw-400 text-grayscale--400'>Quando a gente simplifica o dia a dia, sobra mais tempo pro que realmente importa.</p>
              <p className='fs-16 lh-20 fs-xl-18 lh-xl-22 text-grayscale--400 fw-400 text-grayscale--400'>Pro festival do ano. Pro jogo decisivo. Pro evento que não dá pra perder.</p>
              <p className='fs-16 lh-20 fs-xl-18 lh-xl-22 text-grayscale--400 fw-400 text-grayscale--400'>É pra isso que o SuperApp do Inter existe, e é por isso que estamos com você hoje criando mais um momento inesquecível!</p>
              <p className='fs-16 lh-20 fs-xl-18 lh-xl-22 text-grayscale--400 fw-400 text-grayscale--400'>Bora curtir junto?</p>
            </div>
            <div className='col-12 col-md-6 col-lg-5 offset-lg-2'>
              <ExperienciaForm />
            </div>
          </div>
        </div>
      </S.Section>
    </Layout>
  )
}

export default ParceiroConsignado
